.sr-mid {
  background: #f5f5f5;
  padding: 10rem 0;

  &_tab {
    padding: 3.2rem 0;
    background: #fff;

    .fz-nor {
      padding: 0 2.4rem;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 3.2rem;
    }

    a {
      color: #76787f;
      @include fz-20;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      padding: 1.2rem 2.4rem;
      transition: 0.3s;
      position: relative;
      display: block;

      &:hover {
        color: #213b81;
        transition: 0.3s;
        background: #e4e8f1;
      }

      &.is-active {
        color: #213b81;
        transition: 0.3s;
        background: #e4e8f1;

        &::before {
          content: "";
          width: 0.5rem;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: #2f4ea2;
        }
      }
    }
  }

  .custom-date-input {
    &::before {
      width: 2rem;
      height: 2rem;
    }
  }

  &_desc {
    padding: 3.2rem;
    background: #fff;

    .fz-nor {
      margin-bottom: 2.4rem;
      color: #2f4ea2;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;

      span {
        display: block;
        @include fz-16;
        font-weight: 400;
      }
    }

    label {
      color: #1e2024;
      font-weight: 700;
      line-height: 150%;
    }

    .pri-input {
      padding: 1rem;
      border: 0.1rem solid#BBBBC4;
      margin-bottom: 3.2rem;
      margin-top: 1rem;
      border-radius: 0;
      height: 4.1rem;
    }

    .price-box {
      position: relative;

      .pri-input {
        padding-left: 2rem;
        padding-right: 7rem;
      }
    }

    .price-tag {
      position: absolute;
      top: 2rem;
      left: 1rem;
      font-weight: 700;
      @include fz-14;
    }

    .month-tag {
      position: absolute;
      top: 2rem;
      right: 1rem;
      font-weight: 400;
      @include fz-14;
    }
  }
}

.pri-radio {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 3.2rem;
  p {
    color: #1e2024;
    font-weight: 700;
    line-height: 150%;
    width: 40%;
    flex-shrink: 0;
  }

  label {
    font-weight: 400;
  }

  &_group {
    display: flex;
    gap: 3.2rem;
    flex-wrap: wrap;
  }

  input {
    appearance: auto;
    cursor: pointer;
  }
}

.pri-checkbox {
  display: flex;
  margin-bottom: 3.2rem;
  flex-wrap: wrap;
  row-gap: 1.6rem;

  p {
    color: #1e2024;
    font-weight: 700;
    line-height: 150%;
    width: 40%;
  }

  &_group {
    display: flex;
    gap: 3.2rem;
    flex-wrap: wrap;

    label {
      font-weight: 400;
      cursor: pointer;
    }

    input {
      appearance: auto;
      cursor: pointer;
    }
  }

  &_item {
    display: flex;
  }
}

.tab2-content {
  color: #76787f;
  @include fz-14;
  margin-bottom: 3.2rem;
}

.tab-req {
  margin-bottom: 5rem;
}

.submit-req {
  .pri-btn {
    @include fz-16;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 50%;

    span {
      display: flex;
      align-items: center;
    }
  }
}

.sr-mid_tab {
  position: sticky;
  top: 10rem;
}

.col-sr-mid {
  @include max(1200px) {
    width: 100%;
  }
  @include max(650px) {
    .row {
      gap: 0;
    }

    .col-full {
      width: 100%;
    }
  }
}


.input-style{
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
  margin-top: 1rem;
  border: 0.1rem solid #BBBBC4;
  // padding-left: 1rem;
  .pri-input{
    border: none;
    margin: 0;
  }

  .select2-container{
    width: fit-content !important;
    display: flex;
    align-items: center;
    &::after{
      display: inline-block;
      content: '';
      width: 0.1rem;
      height: 2rem;
      background: #BBBBC4;
    }
  }
  .select2-container--default .select2-selection--single{
    border: none;
    height: 4.1rem;

    .select2-selection__arrow{
      display: none;
    }

    .select2-selection__rendered{
      width: 5rem;
      padding-left: 1rem;
      padding-right: 0;
      @include fz-13;
      color: #1E2024;
    }
  }
}