.banner {
  position: relative;

  &-img {
    width: 100%;
    height: calc(100vw * (500 / 1728));

    @include max(1200px) {
      min-height: 40rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    &_title {
      color: $color-white;
      @include fz-64;
      letter-spacing: -0.256rem;
      text-transform: capitalize;
      line-height: normal;
    }
  }
}

.breadcrumbs {
  display: flex;
  gap: 0.4rem;

  &_url {
    color: $color-white;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.064rem;
    text-transform: capitalize;

    &:last-child {
      color: #bbbbc4;
      pointer-events: none;
    }
  }
}

.sv-mid {
  padding: 8rem 0 10rem;
  &_wrapper {
    max-width: 100.8rem;
    width: 100%;
    margin: 0 auto;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 59.2rem;
    margin: 0 auto;

    .desc-txt {
      margin: 0;
    }
  }

  &_slide {
    margin-top: 4rem;
  }

  .post-slider_nav {
    margin: 4rem auto 0;
  }
}

.sv-mid_slide {
  .swiper-slide {
    max-width: 38.4rem;
    transition: 0.3s;
    display: flex;
    align-items: center;
    transform: translate3d(0) !important;

    @include max(800px){
      max-width: 35rem;
    }

    @include max(580px){
      max-width: 30rem;
    }
  }

  .service-box {
    transition: 0.3s;
    padding: 2rem 1.2rem 0;
    border: 0.1rem solid #d5dcec;
    background: $color-white;
    width: 100%;
  }

  .service-img{
    position: relative;
    padding-top: 98%;

    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      object-fit: cover;
    }
  }
}

.service-txt {
  padding: 2.7rem 0;
  text-align: center;
}

.sm-content {
  padding: 10rem 0;
  text-align: center;

  &_form {
    max-width: 59rem;
    margin: 0 auto;

    p {
      color: #76787f;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .form-box {
    padding: 4rem;
    background: #213b81;
    margin-top: 4rem;
    text-align: left;

    @include max(580px) {
      padding: 4rem 2rem;
    }
  }

  .fz-nor {
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  .form-submit {
    display: flex;
    justify-content: center;
  }

  &_req {
    text-align: start;

    .recomment-post {
      .swiper {
        max-height: 27rem;
      }

      .a-slider_navigator {
        margin-bottom: 4rem;
      }
    }

    .fz-nor {
      text-align: start;
    }
  }

  @include max(980px) {
    .row {
      flex-direction: column;
    }

    .col-sm-content {
      width: 100%;
    }
  }
}

.sv-bot {
  position: relative;

  &_bg {
    width: 100%;
    min-height: 80rem;
    display: flex;

    @include max(580px){
      min-height: 120rem;
    }

    img{
      width: 100%;
      object-fit: cover;
    }
  }

  .container {
    position: absolute;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.form-service {
  max-width: 650px;
  width: 100%;
  padding: 3rem;
  background: rgba(33, 59, 129, 0.85);
  .head-title {
    color: #fff;
  }

  .col-service{
    @include max(580px){
      width: 100%;
    }
  }

  label {
    display: block;
    color: #fff;
  }

  .row-field {
    margin-bottom: 2rem;
    flex-wrap: wrap;
    @include max(580px){
      row-gap: 2rem;
    }
  }

  .pri-input,
  input {
    border-radius: 0;
    background: transparent;
    border: 0.1rem solid #bbbbc4;
    margin-top: 1rem;
    color: #fff;
    width: 100%;
    min-height: 4.1rem;
    padding-left: 1rem;
    font-family: var(--font-pri);

    &:focus {
      outline: none;
    }
  }

  .pri-area {
    border-radius: 0;
    border: 0.1rem solid #bbbbc4;
    background: transparent;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }

  .select2-container--default {
    margin-top: 1rem;
    .select2-selection--single {
      height: 4.1rem;
      background: transparent;
      border-radius: 0;
      border: 0.1rem solid #bbbbc4;

      .select2-selection__rendered {
        color: #fff;
      }
    }
  }
}

.btn-service_submit {
  margin-top: 4rem;
  .pri-btn{
    @include fz-16;
  }
}
