.pri-input,
.pri-area {
  width: 100%;
  border-radius: 0.4rem;
  border: 0.1rem solid $color-white;
  padding: 0.8rem 1.2rem;
  font-family: var(--font-pri);
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: var(--font-pri);
  }

  &.input-s1 {
    border-radius: 0.8rem;
    border: 0.1rem solid #bbbbc4;
    padding: 1rem;
  }
}

.pri-area {
  resize: none;
  border-radius: 0.8rem;
  border: 0.1rem solid #bbbbc4;
  padding: 1rem;
  min-height: 9rem;
  margin-top: 0.8rem;
}

.checkbox-pri {
  position: relative;
  cursor: pointer;
  padding-left: 3rem;
  display: inline-block;

  input {
    display: none;
  }

  &::before{
    content: "";
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid #BBBBC4;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.checkmark{
    color: #76787F;
}

.checkbox-pri input:checked + .checkmark:after {
    content: ''; /* Ký tự checkmark Unicode */
    width: 1.6rem;
    height: 1.6rem;
    background: url(../images/checkmark.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0.3rem;
    left: 0.4rem;
    transition: 0.3s;
}