.p-content {
  padding: 10rem 0;

  @include max(768px){
    padding: 5rem 0;
  }

  .head-title {
    padding-top: 6rem;
    padding-bottom: 6.7rem;
    max-width: 41.6rem;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .tab-content{
    max-width: 90.4rem;
    width: 100%;
    margin: 0 auto;
  }
}

.tab-list {
  display: flex;
  justify-content: center;

  @include max(550px){
    flex-direction: column;
  }
}

.tab-item {
  padding: 1rem 3.2rem;
  border: 0.1rem solid #d5dcec;
  cursor: pointer;
  @include fz-20;
  font-weight: 500;
  transition: 0.3s;

  &.is-active {
    border-color: #213b81;
    background: #213b81;
    color: $color-white;
  }

  &:hover {
    transition: 0.3s;
    border-color: #213b81;
    background: #213b81;
    color: $color-white;
  }
}

.content-txt {
  h2 {
    color: #1e2024;
    font-family: var(--font-pri);
    @include fz-24;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }

  p{
    color: #76787F;
    font-family: var(--font-pri);
    @include fz-16;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3.2rem;
  }

  ol{
    max-width: 80rem;
    width: 100%;
    h2{
      margin-bottom: 0;
    }

    ul{
      margin-bottom: 3.2rem;
      li{
        margin-bottom: 0;
        position: relative;
        margin-left: 3rem;
        padding-left: 1.5rem;
      }

    }
  }
}
