.ct-top {
  padding: 10rem 0;
  text-align: center;

  &_info {
    max-width: 59.2rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 6rem;
  }

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_title {
    .fz-nor {
      color: #1e2024;
      font-weight: 700;
    }
    p {
      color: #76787f;
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
  }

  .row {
    margin: 0 -2.9rem;

    @include max(768px) {
      row-gap: 0;
    }
  }

  .col-ct {
    padding: 0 2.9rem;
    position: relative;

    @include max(768px) {
      width: 100%;
    }

    &:nth-child(2) {
      @include max(768px) {
        padding: 3rem 2.9rem;
        margin: 3rem 0;
        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 30rem;
          height: 0.2rem;
          display: block;
          background: #1e2024;
          border-radius: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }

      @include min(768px) {
        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 0.2rem;
          height: 15rem;
          display: block;
          background: #f4f4f4;
          border-radius: 0.5rem;
          top: 50%;
          transform: translateY(-50%);

          @include max(768px) {
            display: none;
          }
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }
    }
  }
}

.contact {
  .a-bot {
    margin-right: 0;
    justify-content: center;
  }
}

.js-detail-slide2 {
  margin-top: 3.2rem;
}

.detail-img {
  display: flex;
  padding-top: 49.7%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.thumb-img {
  display: flex;
  position: relative;
  padding-top: 68.18%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.contact-block{
  padding-bottom: 5.6rem;
  border-bottom: 0.1rem solid #D9D9D9;
  margin-bottom: 6rem;

  .form-box{
    padding: 3rem;
    background: #213B81;
    max-width: 65.1rem;
  }
  .head-title{
    color: #fff;
  }
}

.map-block{
  margin-bottom: 6rem;
  padding-bottom: 6.3rem;
  border-bottom: 0.1rem solid #D9D9D9;
  .a-bot_map{
    max-width: 100%;
    min-height: 30rem;
    height: 30rem;
  }

  &_txt{
    margin-top: 2.3rem;
    display: flex;
    gap: 0.5rem;
  }
}

.review-block{

  .rating-status{
    @include fz-24;
    line-height: 150%;
    text-transform: capitalize;
  }

  .rating-count{
    color: #A1A4AC;
  }
}

.rating-review {
  max-width: 16rem;
  width: 100%;
  padding: 2.4rem 3rem;
  text-align: center;
  border: 0.1rem solid #BBBBC4;
  height: fit-content;

  .rate-num{
    @include fz-48;
    font-weight: 600;
    line-height: 150%;
  }
}


.review_star-wrapper {
  display: inline-block;
  position: relative;
  .review_star_active {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
  }

}

.form-review{
  p{
    margin-bottom: 1.5rem;
  }

  input{
    margin-bottom: 1rem;
  }

  .checkbox-pri{
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    &::before{
      top: 50%;
      transform: translateY(-50%)
    }
  }

  .pri-btn{
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.view-review {
  .fz-nor {
    font-weight: 700;
    margin-bottom: 5.1rem;

    span{
      @include fz-16;
      font-weight: 400;
    }
  }
}


.rating-review{
  .review_star {
    display: flex;
    gap: 0;
    &.js-review {
      cursor: pointer;
      li {
        background-image: url("../images/detail/star.svg");
        &.is-current {
          background-image: url("../images/detail/star-at.svg");
        }
      }
    }
    &.is-active {
      min-width: max-content;
      li {
        background: url("../images/detail/star-at.svg") center/contain no-repeat;
      }
    }
    li {
      width: 1.6rem;
      height: 1.6rem;
      background: url("../images/detail/star.svg") center/contain no-repeat;
    }
  }
}
