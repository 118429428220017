.m-left-layout {
  margin-left: calc((100vw - 1216px) / 2);
  display: flex;

  @include max(1300px) {
    margin: 0;
    padding-left: 1.6rem;
  }

  @include max(768px) {
    flex-direction: column;
    padding: 0 1.6rem;
    align-items: center;
    position: relative;
  }
}

.a-slider {
  padding: 10rem 0;
  overflow: hidden;

  &_content {
    width: calc((4 / 12 * 1200px) - 1.6rem);
    margin-right: 3.2rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include max(768px) {
      width: 100%;
      margin: 0;

      .content-box {
        margin-bottom: 3rem;
      }
    }
  }

  &_swiper {
    width: 100%;
    overflow: hidden;

    .swiper {
      margin: 0 -1.6rem;
    }

    .swiper-slide {
      padding: 0 1.6rem;
      width: 28.65%;
      transition: 0.3s;
      display: inline-flex;
      max-height: 42.3rem;
      &.swiper-slide-active {
        transition: 0.3s;
        width: 42.65%;
      }
    }

    @include max(1300px) {
      .swiper-slide {
        width: 40%;

        &.swiper-slide-active {
          width: 60%;
        }
      }
    }

    @include max(800px) {
      .swiper-slide {
        &.swiper-slide-active {
          width: 100%;
        }
      }
    }

    @include max(768px) {
      .swiper-slide {
        width: 50%;
        &.swiper-slide-active {
          width: 50%;
        }
      }
    }

    @include max(480px) {
      .swiper-slide {
        width: 100%;
        &.swiper-slide-active {
          width: 100%;
        }
      }
    }
  }

  &_navigator {
    display: flex;
    gap: 2.4rem;
    justify-content: end;

    @include max(768px) {
      position: absolute;
      top: 105%;
      left: 1.6rem;
    }
  }
}

.js-custom-prev,
.js-custom-next {
  width: 4rem;
  height: 4rem;
  cursor: pointer;

  &.swiper-button-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.box-img {
  display: block;
  width: 100%;
  min-height: 42.3rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.a-mid {
  margin-bottom: 10rem;
  &_content {
    margin-bottom: 6rem;
  }

  .head-title {
    text-align: center;
    margin-bottom: 1.6rem;
    line-height: normal;
  }

  .a-mid_sub {
    text-align: center;
    color: #76787f;
    line-height: normal;
  }

  &_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 82.8rem;
    margin: 0 auto;

    .swiper-pagination {
      position: relative;
      bottom: 0;
      margin-top: 2rem;
    }

    .swiper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .swiper-wrapper {
      margin: 0 -3rem;

      @include max(768px) {
        margin: 0 -1.6rem;
      }
    }

    .swiper-slide {
      width: 50%;
      padding: 0 3rem;
      @include max(768px) {
        padding: 0 1.6rem;
      }

      @include max(625px) {
        width: 100%;
      }
    }
  }

  .card-item {
    width: 100%;
    &_img {
      padding-top: 119%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }

  .card-name {
    @include fz-28;
    font-weight: 700;
    line-height: normal;
  }

  .card-info {
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.9rem;
    padding-bottom: 0.9rem;
    border-radius: 5px;
    border-bottom: 0.1rem solid #d9d9d9;

    @include max(768px) {
      flex-direction: column;
      row-gap: 1rem;
    }
  }

  .card-social {
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
      display: block;
    }

    li {
      width: 1.5rem;
      height: 1.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .card-desc {
    color: #76787f;

    p {
      &:first-child {
        margin-bottom: 1.6rem;
      }

      &.card-summary {
        font-family: var(--font-thir);
      }
    }
  }
}

.a-bot {
  display: flex;
  justify-content: flex-end;
  margin-right: 10rem;
  margin-bottom: 10rem;

  @include max(1400px) {
    margin-right: 0;
  }
  &_container {
    max-width: 142.8rem;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-end;

    @include max(980px) {
      flex-direction: column;
    }

    form {
      max-width: 65.1rem;
      padding: 3rem;
      background: #213b81;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      @include max(1300px) {
        position: relative;
        max-width: 100%;
      }

      @include max(980px) {
        max-width: 100%;
        top: 0;
        transform: translateY(0);
      }

      .head-title {
        color: $color-white;
        margin-bottom: 4rem;
      }
    }
  }

  &_map {
    max-width: 105.6rem;
    min-height: 78.9rem;
    width: 100%;

    @include max(980px) {
      min-height: 40rem;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.custom-date-input,
.custom-time-input {
  position: relative;
  &::before {
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    background: url(../images/about/cal.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;
  }

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.custom-time-input {
  &::before {
    background: url(../images/about/time.svg);
  }
}

.form-box {
  .form-field {
    display: flex;
    margin: 0 -1.6rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    margin-bottom: 4rem;

    @include max(480px) {
      margin: 0 -0.5rem;
      margin-bottom: 4rem;
    }
  }

  .field-item {
    width: 50%;
    padding: 0 1.6rem;

    @include max(480px) {
      padding: 0 0.5rem;
    }

    &:last-child {
      width: 100%;
    }

    label {
      display: block;
      color: #fff;
      font-family: Mulish;
      @include fz-16;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    textarea {
      min-height: 10rem;
      width: 100%;
      border: 0.1rem solid #bbbbc4;
      background: transparent;
      resize: none;
      padding: 1rem;
      color: $color-white;
      font-family: var(--font-pri);

      &:focus {
        outline: none;
      }
    }

    input,
    .pri-input {
      border-radius: 0;
      font-family: var(--font-pri);
      width: 100%;
      border: 0.1rem solid #bbbbc4;
      background: transparent;
      padding: 1rem;
      color: $color-white;
      min-height: 4.1rem;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #76787f;
        font-family: var(--font-pri);
      }
    }

    .select2-container {
      margin-top: 1rem;
    }

    .select2-container--default {
      .select2-selection--single {
        border-radius: 0;
        background: transparent;
        // margin-top: 1rem;
        .select2-selection__rendered {
          color: #fff;
          @include fz-13;
        }

        .select2-selection__placeholder {
          color: #76787f;
          font-family: var(--font-pri);
        }
      }
    }
  }

  .fullw-item {
    width: 100%;
  }
}

.form-submit {
  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: var(--font-pri);
    &::after {
      content: "";
      width: 2rem;
      height: 2rem;
      background: url(../images/about/send.svg);
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }
  }
}

@include max(1300px) {
  #wpcf7-f136-p31-o1,
  #wpcf7-f136-p33-o1 {
    width: 50%;
  }
  .a-bot_map {
    width: 50%;
  }
}

@include max(980px) {
  #wpcf7-f136-p31-o1,
  #wpcf7-f136-p33-o1 {
    width: 100%;
  }
  .a-bot_map {
    width: 100%;
  }
}
