// Core
@import "_core/reset";
@import "_core/mona";
@import "_core/variable";
@import "_core/font";
@import "_core/base";
@import "_core/flex";

// Component
@import "_components/header";
@import "_components/footer";

// Layout
@import "_layouts/input";
@import "_layouts/btn";
// Pages
@import "_pages/home";
@import "_pages/about";
@import "_pages/service";
@import "_pages/propertie-type";
@import "_pages/news";
@import "_pages/policy";
@import "_pages/new-detail";
@import "_pages/contact";
@import "_pages/detail";
@import "_pages/service-req";
