.h-top_banner {
  width: 100%;
  height: calc(100vw * (778 / 1728));

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.h-mid {
  padding: 8rem 0;

  @include max(580px){
    padding: 10rem 0;
  }
}

.text_box {
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.btn-read-more {
  max-width: 17.6rem;
  width: 100%;

  .pri-btn {
    width: 100%;
  }
}

.h-content,
.h-bottom {
  padding: 8rem 0 5rem 0;
  background: rgba(55, 55, 64, 0.7);
  position: relative;

  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include max(1200px){
    .row{
      margin: 0 -0.8rem;
    }

    .col-option{
      padding: 0 0.8rem;
    }
  }

  @include max(1024px){
    .row{
      flex-wrap: wrap;
      justify-content: center;
    }
    .col-option{
      width: 50%;
    }
  }

  @include max(580px){
    .col-option{
      width: 100%;
    }
  }
}

.h-bottom {
  padding: 11.8rem 0;

  .text_box {
    max-width: 62.4rem;
  }

  .desc-txt {
    margin-bottom: 3.2rem;
    text-align: center;
  }
}

.option-item {
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.6rem;

  @include max(1200px){
    padding: 1.6rem;
  }

  .desc-txt {
    margin: 0;
  }

  &_icon {
    width: 8.8rem;
    height: 8.8rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.h-service {
  padding: 8rem 0 9.6rem 0;

  .row{
    justify-content: center;
  }

  @include max(1200px){
    .row{
      margin: 0 -0.8rem;
    }

    .col-service{
      padding: 0 0.8rem;
    }
  }

  @include max(768px){
    .col-service{
      width: 50%;
    }
  }

  @include max(580px){
    .col-service{
      width: 100%;
    }
  }



  .desc-txt {
    margin-bottom: 4rem;
  }
}

.service-item {
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 48.11%,
      rgba(0, 0, 0, 0.25) 75.36%,
      rgba(0, 0, 0, 0.4) 100%
    );
    z-index: 1;
  }

  &_img {
    width: 100%;
    padding-top: 156.25%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &_content {
    position: absolute;
    left: 3.2rem;
    bottom: 3.2rem;
    z-index: 1;

    @include max(768px){
      left: 2rem;
      bottom: 2rem;
    }
    .sub-title {
      color: $color-white;
      text-align: left;
      margin-bottom: 2.4rem;
    }
  }
}

.h-end {
  padding: 8rem 0;

  @include max(1450px){
    width: 90%;
    margin: 0 auto;
  }

  @include max(768px){
    width: 100%;
  }

  &_slider {
    position: relative;
  }

  .swiper-pagination {
    bottom: -10%;

    @include max(768px){
      bottom: -15%;
    }
  }

  .text-box {
    max-width: 45.3rem;
    margin: 0 auto;

    .desc-txt {
      opacity: 0.8;
      margin-bottom: 3rem;
    }
  }
}

.review-item {
  padding: 3.2rem;
  border: 0.1rem solid $color-text3;

  @include max(768px){
    padding: 1.6rem;
  }

  &_logo {
    width: 4.3rem;
    height: auto;
    margin-bottom: 0.8rem;
  }

  .desc-txt {
    margin-bottom: 2.4rem;
  }

  &_avt {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;

    @include max(768px){
      width: 5rem;
      height: 5rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_name {
    color: $color-title;
    font-family: var(--font-sec);
    @include fz-20;
    font-weight: 500;
    line-height: 170%;
    text-transform: capitalize;
  }

  &_author {
    display: flex;
    column-gap: 2.4rem;

    @include max(768px){
      gap: 1rem;
    }
  }

  &_info {
    flex: 1;
  }
}

.review_star-wrapper {
  display: inline-block;
  position: relative;
  .review_star_active {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
  }
}

.review_star {
  display: flex;
  gap: 0.4rem;
  &.js-review {
    cursor: pointer;
    li {
      background-image: url("../images/ico_star.svg");
      &.is-current {
        background-image: url("../images/ico_star_active.svg");
      }
    }
  }
  &.is-active {
    min-width: max-content;
    li {
      background: url("../images/ico_star_active.svg") center/contain no-repeat;
    }
  }
  li {
    width: 2rem;
    height: 2rem;
    background: url("../images/ico_star.svg") center/contain no-repeat;
  }
}
.swiper-pagination-bullet {
  transition: 0.3s;
  &.swiper-pagination-bullet-active {
    border-radius: 12rem;
    background: $color-pri;
    width: 3.2rem;
    transition: 0.3s;
  }
}

.custom-prev-btn,
.custom-next-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.75);

  @include max(880px){
    display: none;
  }

  &.swiper-button-disabled{
    opacity: 0.2;
    pointer-events: none;
  }
}

.custom-prev-btn {
  right: 102%;

  @include max(1200px){
    right: 101%;
  }
}

.custom-next-btn {
  left: 102%;

  @include max(1200px){
    left: 101%;
  }
}

.swiper-icon {
  width: 3.2rem;
  height: 3.2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.sub-title {
  color: $color-text3;
  text-align: center;
  @include fz-28;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 0.8rem;
}

.fz-nor {
  @include fz-24;
}

.head-title {
  color: $color-title;
  @include fz-36;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: capitalize;
  margin-bottom: 1.6rem;
}

.desc-txt {
  color: $color-text2;
  line-height: 150%;
  margin-bottom: 1.6rem;
}

.c-white {
  color: $color-white;
}

.c-cream {
  color: $color-text;
}

.t-center {
  text-align: center;
}

.pri-btn {
  width: fit-content;
  display: block;
  padding: 1.1rem 2.3rem;
  background: $color-pri;
  color: $color-white;
  text-align: center;
  font-weight: 700;
  line-height: 2.4rem;
  position: relative;
  z-index: 0;
  border: 0.1rem solid $color-pri;

  &::before {
    position: absolute;
    content: "";
    right: 0;
    width: 0;
    top: 0;
    bottom: 0;
    background: $color-white;
    transition: 0.3s ease;
    z-index: -1;
  }

  &:hover {
    transition: 0.3s ease;
    color: $color-pri;
  }

  &:hover::before {
    left: 0;
    width: 100%;
  }

  // color btn
  &.btn-trans {
    border: 0.1rem solid $color-white;
    background: rgba(255, 255, 255, 0.1);

    &::before {
      background: $color-pri;
    }

    &:hover {
      transition: 0.3s ease;
      color: $color-white;
      border-color: $color-pri;
    }
  }

  &.btn-white {
    background: #fff;
    color: #2F4EA2;
    font-size: 1.6rem;

    &::before {
      background: #2F4EA2;
    }

    &:hover {
      transition: 0.3s ease;
      color: $color-white;
      border-color: transparent;
    }
  }

  &.ic-submit {
    display: flex;
    gap: 0.5rem;
    transition: 0.3s;

    &:hover{
      &::after{
        filter: grayscale(100%) brightness(1000%);
        transition: 0.3s;
      }
    }
    &::after {
      content: "";
      width: 2rem;
      height: 2rem;
      background: url(../images/about/send.svg);
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }
  }
}
