.wrapper-slide {
  max-width: 100.8rem;
  margin: 0 auto;
  padding: 10rem 1.5rem 5rem;

  @include max(768px) {
    padding: 5rem 1.5rem;
  }
}

.detail-carosel {
  margin-top: 4rem;
  margin-bottom: 6rem;

  @include max(768px) {
    margin-bottom: 0;
  }
}

.write-review {
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;

  @include max(480px) {
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
  }
}

.main-carousel {
  position: relative;

  .js-custom-prev,
  .js-custom-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include max(1200px) {
      display: none;
    }
  }

  .js-custom-prev {
    right: calc(100% + 3.2rem);
  }

  .js-custom-next {
    left: calc(100% + 3.2rem);
  }
}

.mapping-list {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  .desc-block,
  .contact-block,
  .map-block,
  .review-block {
    padding: 0;
    border: 0;
    margin: 0;
  }

  @include max(550px) {
    flex-direction: column;
    align-items: center;
  }

  li {
    display: flex;
    align-items: center;

    &.is-active {
      button {
          color: #1e2024;
          &::before {
            width: 100%;
            transition: 0.3s;
          }
      }
    }

    &:not(:last-child)::after {
      content: "";
      width: 0.1rem;
      height: 3rem;
      display: block;
      margin: 0 1.5rem;
      border-radius: 0.5rem;
      background: #d5dcec;

      @include max(550px) {
        display: none;
      }
    }
  }

  button {
    padding: 1rem 2rem;
    color: #76787f;
    @include fz-20;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    position: relative;
    transition: 0.3s;

    &::before {
      content: "";
      width: 0;
      height: 0.1rem;
      background: #1e2024;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: 0.3s;
    }

    &:hover {
      color: #1e2024;
      transition: 0.3s;
      &::before {
        width: 100%;
        transition: 0.3s;
      }
    }
  }
}

.desc-block {
  padding-bottom: 6rem;
  margin-bottom: 5.6rem;
  border-bottom: 0.1rem solid #d9d9d9;
  &_penefit {
    padding: 2rem 6rem;
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;

    @include max(500px) {
      padding: 2rem;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    p {
      font-weight: 500;
    }
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &_content {
    .txt-content {
      @include fz-18;
      font-weight: 500;

      span {
        @include fz-16;
        font-weight: 400;
      }
    }
  }

  &_txt {
    margin-top: 4rem;
    margin-bottom: 4rem;

    p {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin-bottom: 3rem;

      li {
        &::before {
          content: "*";
          display: inline-flex;
          @include fz-16;
          margin-right: 0.5rem;
        }
      }
    }
  }

  &_pdf {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
}

.pdf-download {
  max-width: calc((717 * 100%) / 801);
  width: 100%;
  border: 0.1rem solid #d5dcec;
  background: #fff;
  padding: 4rem 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include max(600px) {
    padding: 6rem 3rem 4rem;
  }

  &_block {
    max-width: 43.9rem;
    width: 100%;
    margin-bottom: 2rem;

    @include max(1200px) {
      width: 100%;
    }

    .pri-btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      background: #ec1e22;
      border-color: #ec1e22;

      path {
        transition: 0.3s;
      }

      &:hover {
        color: #ec1e22;

        path {
          transition: 0.3s;
          stroke: #ec1e22;
        }
      }
    }
  }
}

.pdf-decor1 {
  position: absolute;
  left: 4.5rem;
  z-index: -1;
  max-width: 14.6rem;
  width: 100%;
  top: -3.5rem;

  @include max(768px) {
    max-width: 8.8rem;
  }
}

.pdf-decor2 {
  position: absolute;
  left: 0;
  z-index: 1;
  max-width: 15.3rem;
  width: 100%;
  top: -3.5rem;

  @include max(768px) {
    max-width: 9rem;
    left: 2rem;
  }
}

.download-btn {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;

  @include max(600px) {
    flex-direction: column;
    align-items: start;
  }
}

.download-info {
  p {
    display: flex;
    color: #76787f;
    align-items: center;
    gap: 0.5rem;
  }
}

.fz-20_bold {
  @include fz-20;
  font-weight: 500;
}

.review-list {
  li {
    margin-bottom: 4rem;
  }
  .name {
    margin-bottom: 1.6rem;
  }

  .review_star {
    li {
      margin-bottom: 0;
    }
  }
}

.detail-main {
  margin-bottom: 10rem;

  @include max(1150px) {
    .row-d-main {
      flex-direction: column;
    }

    .col-d-main {
      width: 100%;
    }
  }

  &_avail {
    padding: 2rem 1.5rem;
    background: #f8f8f8;
    width: 100%;
  }

  .social-list {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .social-item {
    a {
      width: 4rem;
      height: 4rem;
    }
  }

  .tag-list {
    margin-top: 2rem;
  }
}

.avail-list {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.avail-item {
  display: flex;
  gap: 2rem;
  margin-bottom: 3.2rem;

  &:last-child {
    margin-bottom: 0;
  }

  &_img {
    width: 9.7rem;
    height: 9.7rem;
    border-radius: 50%;
    overflow: hidden;
  }

  &_txt {
    color: #000;
  }
}

.mail-contact {
  margin: 1rem 0;
  @include fz-14;
  line-height: normal;
  color: #76787f;
}

.phone-contact {
  color: #1e2024;
}

.detail-location {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-left: 0.5rem;
    color: #76787f;
  }

  a {
    color: #213b81;
    @include fz-14;
    text-decoration-line: underline;

    // &::after{
    //   display: block;
    //   content: "";
    //   width: 100%;
    //   height: 0.1rem;
    //   background: #213B81;
    // }
  }
}

.detail-bot {
  margin-bottom: 10rem;

  &-head {
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;

    @include max(501px) {
      gap: 4rem;
      position: relative;
    }

    .head-title {
      margin: 0;

      @include max(501px) {
        width: 100%;
      }
    }
  }

  .action-bar {
    display: flex;
    align-items: center;

    a {
      margin-left: 4rem;
      font-weight: 700;

      @include max(501px) {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 0.1rem;
        background: #1e2024;
      }
    }
  }

  .js-custom-prev {
    margin-right: 2rem;
  }
}


.property-feat{
  margin-bottom: 11rem;

  &_title{
    @include fz-24;
    font-weight: 700;
    margin-bottom: 1.6rem;
  }
}

.prop-list{
  max-width: 50rem;
  margin: 0 -1.6rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.6rem;

  @include max(425px){
    flex-direction: column;
  }

    .prop-item{
      padding: 0 1.6rem;
      width: 50%;
      display: flex;

      @include max(425px){
        width: 100%;
      }

      &_img{
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        margin-right: 0.8rem;

        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
}