.pt-mid {
  padding: 7.5rem 0 10rem;

  .head-title {
    text-align: center;
  }

  &_filter {
    label {
      display: block;
    }

    @include max(1200px) {
      .row {
        margin: 0 -1rem;
      }

      .col-filter {
        padding: 0 1rem;
      }
    }

    @include max(850px) {
      .col-filter {
        width: 33.33%;
      }
    }

    @include max(600px) {
      .col-filter {
        width: 50%;
      }
    }

    @include max(400px) {
      .row {
        margin: 0 -0.5rem;
      }

      .col-filter {
        padding: 0 0.5rem;
      }
    }
  }

  &_product {
    margin-top: 4rem;
    margin-bottom: 6rem;

    @include max(1200px) {
      .row {
        margin: 0 -1rem;
      }

      .col-pd {
        padding: 0 1rem;
      }
    }
  }
}

@include max(1024px) {
  .col-pd {
    width: 50%;
  }
}

@include max(768px) {
  margin-bottom: 2rem;
}

@include max(550px) {
  .col-pd {
    width: 100%;
  }
}

.list-preview{
  img{
    display: none;
  }
}

.pd-item {
  border: 0.1rem solid #d5dcec;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &_content{
    margin-top: auto;
  }

  .pri-btn {
    padding: 1.2rem 2.4rem;
  }

  .preview-img {
    position: absolute;
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    top: 1.5rem;
    right: 1.5rem;
    background-image: url(../images/proptypes/preview1.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
    display: none;
    transition: 0.3s;
  }

  &_img {
    padding-top: 80%;
    position: relative;
    margin-bottom: 2rem;
    transition: 0.3s;

    &.preview-box {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        transition: 0.3s;
      }
      &:hover {
        .preview-img {
          transition: 0.3s;
          display: block;
        }

        .pd-item_tag {
          z-index: 3;
        }

        &::after {
          background: rgba(0, 0, 0, 0.6);
          transition: 0.3s;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &_tag {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .tag-pd{
      padding: 0.7rem 1.6rem;
      background-color: #474747;
      color: var(--White, #fff);
      @include fz-14;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .pd-name {
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
  }

  .second-title {
    span {
      @include fz-16;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &_locate {
    color: #76787f;
    @include fz-14;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin: 2rem 0;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      content: "";
      width: 1.4rem;
      height: 1.6rem;
      display: block;
      margin-right: 0.5rem;
      background: url(../images/proptypes/map.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .pd-name {
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      color: #2f4ea2;
    }
  }

  &_box {
    display: flex;
    column-gap: 1.6rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  &_utiliti {
    padding: 0.7rem 1.6rem;
    background: #f6f8ff;
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    align-items: center;
    border-radius: 1rem;
    transition: 0.3s;

    &:hover{
      background: #2f4ea2;
      p{
        color: #fff
      }

      img{
        filter: brightness(0) invert(1) grayscale(100%);
      }
    }

    p {
      @include fz-14;
      font-style: normal;
      font-weight: 400;
      transition: 0.3s;
    }

    .utiliti-img {
      width: 1.4rem;
      height: 1.4rem;
      display: inline-flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: 0.3s;
      }
    }
  }

  &_btn {
    span {
      margin-left: 1rem;
    }

    .pri-btn {
      display: flex;
      align-items: center;
      padding: 0.7rem 3.2rem;

      @include max(600px) {
        padding: 0.7rem 2rem;
      }
    }
  }

  &_ic {
    padding: 0.15rem 0.4rem;
    display: flex;
    align-items: center;
  }
}

.view-per-page {
  color: #1e2024;
}

.pd-name {
  color: #1e2024;
  @include fz-20;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.second-title {
  color: #1e2024;
  @include fz-24;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

.third-title {
  @include fz-18;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.select2-container--default .select2-selection--single {
  height: 4.4rem;
  display: flex;
  align-items: center;

  .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);

    &::after {
      display: block;
      content: "";
      width: 2.4rem;
      height: 2.4rem;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      right: 1rem;
      position: absolute;
      background-image: url(../images/ic-drop.svg);
      background-size: contain;
      background-repeat: no-repeat;
      transition: 0.3s;
    }
    b {
      display: none;
    }
  }
}

.select2-container--default {
  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        &::after {
          transition: 0.3s;
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
  }
}

.pt-btn-submit {
  display: flex;
  align-items: end;
  position: relative;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: var(--fomt-pri);
    @include fz-16;
    span {
      display: inline-flex;
      align-items: center;
    }

    @include max(600px) {
      width: 100%;
      max-height: 4.4rem;
      justify-content: center;
    }

    @include max(400px) {
      padding: 1rem;
    }
  }
}

.pt-mid_pagination {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #d5dcec;

  @include max(768px) {
    flex-direction: column;
    row-gap: 3rem;
    align-items: start;
  }
}

.pagination-pri {
  @include max(768px) {
    margin: 0 auto;
  }
  .page-numbers {
    display: flex;
    align-items: center;
  }

  li {
    margin-right: 2rem;
    .prev {
      margin-right: 2rem;
      border: 0.1rem solid #d5dcec;
      transition: 0.3s;
      color: #000;
      padding: 1rem;

      &:hover {
        transition: 0.3s;
        background: $color-pri;
        color: $color-white;
      }

      @include max(425px) {
        margin-right: 2rem;
      }
    }

    .next {
      margin-left: 2rem;
      border: 0.1rem solid #d5dcec;
      margin-right: 0;
      transition: 0.3s;
      padding: 1rem;
      color: #000;

      &:hover {
        transition: 0.3s;
        background: $color-pri;
        color: $color-white;
      }

      @include max(425px) {
        margin-left: 2rem;
      }
    }

    .current {
      color: #1e2024;
    }

    a {
      color: #76787f;
    }
  }
}

.pri-btn {
  &.white-btn {
    background: #fff;
    color: #76787f;
    transition: 0.3s;

    circle,
    ellipse,
    svg {
      transition: 0.3s;
    }

    &:hover {
      color: #fff;
      circle,
      ellipse,
      svg {
        transition: 0.3s;
        fill: #fff;
      }
    }

    &::before {
      background: #2f4ea2;
    }
  }
}
