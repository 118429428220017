:root {
    --font-pri: 'Mulish', serif;
    --font-sec: 'Poppins', serif;
    --font-thir: 'Roboto', serif;
    --size-hd: 10rem;
}

$color-pri: #2F4EA2;
$color-title: #1E2024;
$color-white: #FFF;
$color-text: #E2E8F0;
$color-text2: #76787F;
$color-text3: #E2E4E9;

@mixin max($value) {
    @media screen and (max-width: $value) {
        @content;
    }
}
@mixin min($value) {
    @media screen and (min-width: $value) {
        @content;
    }
}