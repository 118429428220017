.m-header {
  border-bottom: 0.1rem solid #bbbbc4;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(0.2rem);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @include max(768px) {
    min-height: 9.3rem;
    transition: 0.3s;
  }
  .container {
    position: relative;
  }

  .js-search-item {
    cursor: pointer;
    display: flex;
    align-items: center;

    @include max(768px) {
      display: none;
    }
  }

  .search-input {
    max-width: 30rem;
    width: 100%;
    position: absolute;
    right: 1.6rem;
    top: 101%;
    display: none;

    input {
      border-radius: 0;
      padding: 1.5rem 4rem 1.5rem 1.5rem;
    }

    .submit-search {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }
  }

  .pri-input {
    border: 0.1rem solid $color-text;
  }

  &_logo {
    display: none;
    max-width: 13.4rem;
    height: auto;
    position: absolute;

    @include max(1200px) {
      top: 50%;
      left: 1.6rem;
      transform: translateY(-50%);
      display: block;
    }

    a {
      display: block;
    }
  }

  &_content {
    transition: 0.3s;
  }

  @include max(768px) {
    .social-head {
      display: block;
      width: 100%;
      margin-top: auto;
      width: 100%;
      background: #1b3374;
      padding: 3rem;

      .social-list {
        margin-bottom: 2rem;
      }

      .social-item {
        background: #fff;
      }

      .social-box_img {
        max-width: 1.5rem;
        height: 1.5rem;
        display: inline-flex;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  @include min(768px) {
    .social-head {
      display: none;
    }
  }
}

.menu-list {
  display: flex;
  gap: 4rem;
  // min-height: 2.8rem;

  li {
    form {
      width: 100%;
    }
  }

  @include max(900px) {
    gap: 0;
  }

  @include max(768px) {
    width: 100%;
    flex-direction: column;
    li {
      width: 100%;
      margin-bottom: 2.4rem;
      &:last-child {
        display: flex;
        gap: 0.5rem;
      }
    }

    a {
      display: block;
      width: fit-content;
    }
  }

  @include min(768px) {
    li {
      position: relative;
      padding: 2.3rem 0;
      transition: 0.3s;

      a {
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 1px;
          background: #1e2024;
          position: absolute;
          bottom: 1.8rem;
          transition: 0.3s;
        }
        &:hover {
          &::after {
            width: 100%;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  align-items: center;

  ul {
    &:nth-child(3) {
      margin-right: 5rem;
    }
  }

  form {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);

    @include max(768px) {
      position: relative;
      top: 0;
      right: unset;
      transform: unset;
      margin-bottom: 2.4rem;
    }
  }

  @include max(1200px) {
    justify-content: end;
    gap: 4rem;
  }

  @include max(900px) {
    gap: 3rem;
  }

  @include max(768px) {
    position: absolute;
    top: 100%;
    left: 100%;
    transition: 0.5s;
    margin: 0;
    width: 100vw;
    background: #fff;
    height: 100vh;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-top: 3rem;
    gap: 0;
    overflow: hidden auto;
    z-index: 100;
    transform: translateZ(0);

    .menu-list {
      padding: 0 3rem;
    }

    .search-input {
      padding-left: 3rem;
    }

    &.is-active {
      left: 0%;
      transition: 0.5s;
    }
  }

  @include max(768px) {
    .search-input {
      display: block;
      position: relative;
      right: 0;
      top: 0;
    }
  }

  @include min(768px) {
    .search-input {
      display: none !important;
    }
  }
}
// .m-header_mlogo {

// }

.bar1,
.bar2,
.bar3 {
  width: 3rem;
  height: 0.2rem;
  background-color: #333;
  margin: 0.6rem 0;
  transition: 0.4s;
}

.m-header_btnmenu {
  display: none;
}

@include max(768px) {
  .m-header_btnmenu {
    display: block;
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
    cursor: pointer;
    &.is-active {
      .bar1 {
        transform: translate(0, 0.8rem) rotate(-45deg);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        transform: translate(0, -0.8rem) rotate(45deg);
      }
    }
  }
}

.hidden {
  overflow: hidden;
}

.style-logo {
  max-width: 13.4rem;
  width: 100%;
  display: block;

  &::after {
    display: none !important;
  }
}

@include max(1200px) {
  .logo-header {
    display: none;
  }
}

.logo-header {
  li {
    padding: 0;
  }
}

.menu-item {
  &.dropdown {
    &.is-active {
      &::after {
        transition: 0.3s;
        transform: rotate(-180deg);
      }
    }
    &::after {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      display: inline-block;
      vertical-align: middle;
      background: url(../images/ic-drop.svg);
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 1rem;
      transition: 0.3s;

      @include max(768px) {
        position: absolute;
        top: 0.5rem;
        right: 0;
      }
    }
    @include max(768px) {
      flex-direction: column;
      position: relative;
    }

    @include min(768px) {
      &:hover {
        &::after {
          transition: 0.3s;
          transform: rotate(-180deg);
        }
      }
    }
    &:hover {
      .menu-list {
        transition: 0.3s;
        pointer-events: initial;
        @include min(768px) {
          opacity: 1;
        }
        @include max(768px) {
          transition: none;
        }
      }
    }
    .menu-list {
      transition: 0.3s;
      min-width: 25rem;
      position: absolute;
      top: 100%;
      background: #fff;
      display: flex;
      padding: 1.6rem;
      flex-direction: column;
      margin: 0;
      gap: 1.6rem;
      pointer-events: none;

      @include min(768px) {
        opacity: 0;
      }
      @include max(768px) {
        padding: 0 1.6rem;
        position: relative;
        top: 0;
        left: 0;
        display: none;
        transition: none;
      }

      .menu-item {
        padding: 0;
        margin-bottom: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      li {
        transition: none;
        a {
          &::after {
            bottom: 0;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bxs-chevron-down {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
