.m-footer {
  background: #1b3374;
}

.footer-box {
  padding-top: 5.6rem;
  padding-bottom: 5rem;
  flex-wrap: wrap;
  @include min(1024px) {
    justify-content: space-between;
  }
  .col-foot {
    &:nth-child(1) {
      max-width: 32.2rem;
    }
    &:nth-child(2) {
      max-width: 24.5rem;
    }
    &:nth-child(1) {
      max-width: 31.6rem;
    }
    &:nth-child(1) {
      max-width: 27.4rem;
    }

    @include max(1024px) {
      max-width: calc(4 / 12 * 100%) !important;
      width: 100%;
    }

    @include max(768px) {
      max-width: 50% !important;
    }
    @include max(500px) {
      max-width: 100% !important;
    }
  }

  .pd-name {
    color: $color-white;
    min-height: 5rem;
    display: flex;
    align-items: flex-end;
    margin-bottom: 2.4rem;
  }

  .pri-input {
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  .pri-btn {
    border-radius: 0.4rem;
    padding: 0.8rem 1.2rem;
    @include fz-14;
    font-weight: 600;
    overflow: hidden;
  }
}
.foot-logo {
  max-width: 16.5rem;
  width: 100%;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.foot-desc {
  color: $color-white;
  @include fz-14;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.8;
  margin-top: 1.6rem;
}

.list-type {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -1.6rem;
  row-gap: 0.8rem;

  .type-item {
    width: 50%;
    padding: 0 1.6rem;
  }

  a{
    position: relative;
    &::before{
      content: '';
      display: block;
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      width: 0;
      height: 0.1rem;
      background-color: #fff;
      transition: 0.3s;
    }

    &:hover{

      &::before{
        width: 100%;
        transition: 0.3s;
      }
    }

  }
}

.list-type,
.list-contact {
  a {
    color: $color-white;
    @include fz-14;
    font-style: normal;
    font-weight: 400;
  }
}

.list-contact {
  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }
  a {
    display: flex;
    span {
      margin-left: 0.8rem;
    }
  }
}
.contact-item {
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.txt-blur {
  color: #f1f5f9;
  @include fz-14;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.8;
}

.scroll-top {
  position: fixed;
  right: 3%;
  bottom: 15%;
  display: none;
  z-index: 1049;

  @include max(1400px){
    bottom: 18%;
  }

  button {
    width: 4.8rem;
    height: 4.8rem;
    background-color: #E2E4E9;
    border-radius: 50%;
  }
}
