@import url("../font/Themify/stylesheet.css");
@import url("../font/Roboto/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 10px;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: var(--font-pri);
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;
    color: #1E2024;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;
}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.main {}

.container {
    width: 100%;
    max-width: 124.8rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 16px;
}



.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}


img {
    max-width: 100%;
    height: auto;
}

.t-center {
    text-align: center;
}

.fw-b {
    font-weight: bold;
}

.fsi {
    font-style: italic;
}

.t-up {
    text-transform: uppercase;
}

.tabBtn {
    cursor: pointer;
}

.gItem {
    cursor: pointer;
}

.global-title {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}