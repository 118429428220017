.nl-mid {
  padding: 10rem 0;

  @include max(1250px){
    &_row{
      flex-direction: column;
    }

    .col-nl-mid{
      width: 100%;
    }
  }


  .head-title {
    margin-bottom: 3.8rem;
  }

  &_author {
    display: flex;
    gap: 1.6rem;
    margin-bottom: 8.7rem;

    p {
      color: #76787f;
      display: flex;
      span {
        &::before {
          content: "";
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          margin: 0 0.8rem;
          background: #76787f;
        }
      }
    }
  }

  .pd-item_social{
    margin-top: 6.1rem;
    margin-bottom: 4rem;

    p{
      color: #76787F;
    }
  }

  .social-item{
    width: 4rem;
    height: 4rem;

    a{
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    img{
      width: 100%;
    }
  }
}

.author_name {
  color: #1e2024;
  @include fz-16;
  font-style: normal;
  font-weight: 600;
}

.author_avt {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 10rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .post_img {
    width: 100%;
    margin-bottom: 3.8rem;
  }
}

.post-img{
  display: flex;
  margin-bottom: 3.8rem;
}

.post-content {
  h2{
    @include fz-24;
    font-style: normal;
    font-weight: 600;
  }

  p{
    @include fz-16;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2.6rem;
  }
}

.post-slider{

  position: relative;

  .slider-box{
    overflow: hidden;
  }

  .swiper{
    margin: 0 -1.4rem;
  }

  .swiper-slide{
    padding: 0 1.6rem;
    width: 33.33%;
    transition: 0.3s;
    display: inline-flex;

    @include max(768px){
      width: 50%;
    }

    @include max(480px){
      width: 100%;
    }
  }

  &_img{
    display: flex;
  }

  &_nav{
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 27.8rem;
    margin: 3rem auto;
    z-index: 11;

    .swiper-pagination-bullets{
      bottom: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .js-custom-prev, .js-custom-next{
      position: relative;
      z-index: 11;
    }
  }
}


.post-tag{
  display: flex;
  align-items: start;
  gap: 4rem;
  margin-bottom: 6.1rem;

}

.tag-list{
  display: flex;
  align-items: center;
  gap: 0.8rem;
  flex-wrap: wrap;

  .tag-item{
    padding: 0.8rem 1rem;
    color: #1E2024;
    @include fz-14;
    background-color: #ECE9E9;
  }
}


.submit-faq{

  @include max(480px){
    .row{
      flex-direction: column;
      gap: 1.5rem;
    }

    .col-faq{
      width: 100%;
    }
  }


  &_field{
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .input-s1{
    margin-top: 0.8rem;
  }

  .pri-area{
    margin-bottom: 1.5rem;
  }

  .pri-btn{
    margin-top: 3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover{
      path{
        transition: 0.3s;
        stroke: #2F4EA2;
      }
    }
  }
}

.post-comment{
  margin-top: 8rem;

  &-rating{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    .review_star{
      li{
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  @include max(1250px){
    margin-bottom: 8rem;
  }
  .fz-nor{
    margin-bottom: 4rem;
  }

  &_avt{
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 50%;
    overflow: hidden;
  }

  &_info{
    .name{
      margin-bottom: 2rem;
      color: #031903;
      @include fz-24;
      font-weight: 600;
      line-height: normal;
    }

    .date{
      color: #A7BAA8;
      font-family: Poppins;
      @include fz-14;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.row-item{
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -0.8rem;
}

.rented{
  color: #228324;
  @include fz-14;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 1rem;

  &::before{
    content: '';
    display: inline-flex;
    width: 1.2rem;
    height: 1.2rem;
    background: url(../images/detail/checked.svg);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}

.col-avt{
  width: fit-content;
  flex-shrink: 0;
  padding: 0 0.8rem;
}

.col-cmt{
  flex: 1;
  padding: 0 0.8rem;
}


.interact{
  display: flex;
  gap: 1.6rem;
  margin-top: 2rem;
  font-family: Poppins;
  line-height: normal;

  &-act{
    display: flex;
    align-items: flex-end;
    gap: 0.6rem;
    color: #031903;
    @include fz-14;
  }
}

.act-btn{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.act-count{
  color: #A7BAA8;
  font-weight: 300;
}

.recomment-post{
  .fz-nor{
    font-weight: 600;
    color: #000;
    margin-bottom: 3rem;
  }

  .swiper{
    max-height: 42rem;
  }

  .swiper-slide{
    margin-bottom: 1.5rem;
  }

  .a-slider_navigator{
    justify-content: start;
    margin-top: 3rem;
    padding-bottom: 4rem;
    border-bottom: 0.2rem solid #D5DCEC;

    @include max(768px){
      position: unset;
      margin-top: 1rem;
    }
  }


}

.rec-item{
  display: flex;
  gap: 1rem;

  &:hover{
    .rec-item_title{
      color: $color-pri;
      transition: 0.3s;
    }

    .rec-item_box{
      img{
        transition: 0.3s;
        transform: scale(1.1);
      }
    }
  }

  &_info{
    flex: 1;
  }

  &_box{
    position: relative;
    padding-top: 70%;
    overflow: hidden;
  }

  &_img{
    max-width: 17.6rem;
    width: 100%;

    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: 0.3s;
  }
}

.info-box{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  img{
    flex-shrink: 0;
  }

  span{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1;
    @include fz-14;
    color: #76787F;
    font-weight: 300;
  }
}

.nl-bottom{
  .head-title{
    margin-bottom: 4rem;
  }
  .pri-btn{
    margin: 2rem 0;
  }
  .swiper-wrapper{
    margin: 0 -1.6rem;

    @include max(1024px){
      margin: 0 -0.8rem;
    }
  }

  .swiper-slide{
    width: 33.33%;
    padding: 0 1.6rem;

    @include max(1024px){
      width: 50%;
      padding: 0 0.8rem;
    }

    @include max(550px){
      width: 100%;
    }
  }

  .pd-item{
    padding: 2rem;
  }

  .post-slider_nav{
    margin-bottom: 10rem;
  }

  .swiper{
    display: flex;
    justify-content: center;
    margin: 0 -1.6rem;
  }
}