.new-mid {
  margin: 10rem 0;

  @include max(768px){
    margin: 5rem 0;
  }
  &_post {
    margin: 4rem 0 6rem;
    .pd-item_img{
      padding-top: 84%;
    }

    .pri-btn{
      margin: 2rem 0;
    }
  }

  &_tab{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 1rem;

    @include max(550px){
      margin: 0 -1.5rem;
      flex-direction: column;
      align-items: center;
    }

    .tab_item{
      display: flex;
      align-items: center;

      @include max(550px){
        width: 100%;
        padding: 0 1.5rem;
        justify-content: center;
      }

      &::after{
        content: "";
        width: 0.1rem;
        height: 70%;
        margin: 0 1.5rem;
        display: inline-block;
        background: #D5DCEC;

        @include max(550px){
          margin: 0 1rem;
          display: none;
        }
      }

      &:last-child{
        &::after{
          display: none;
        }
      }

      .tab-style{
        padding: 1rem 2rem;
        position: relative;
        @include fz-20;
        font-weight: 600;
        color: #76787F;
        transition: 0.3s;

        @include max(550px){
          padding: 1rem;
          width: fit-content;
        }

        &::before{
          content: '';
          display: block;
          position: absolute;
          bottom: -0.1rem;
          left: 0;
          width: 0%;
          height: 0.1rem;
          background-color: #1E2024;
          transition: 0.3s;
        }

        &:hover{
          transition: 0.3s;
          color: #1E2024;
          &::before{
            width: 100%;
            transition: 0.3s;
          }
        }

        &.is-active{
          color: #1E2024;
          &::before{
            width: 100%;
          }
        }
      }

    }
  }
}

.pd-item_info{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}

.date-info{
  color:#76787F;
  @include fz-14;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;

  span{
    margin-left: 0.5rem;
  }
}

.type-info{
  padding: 0.7rem 1.6rem;
  border: 0.1rem solid #BBBBC4;
  width: fit-content;
  color: #76787F;
  @include fz-14;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pd-item_social{
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.social-list{
  display: flex;
  gap: 0.8rem;
  a{
    padding: 0.8rem;
    border: 0.1rem solid #BBBBC4;
    display: flex;
    transition: 0.3s;

    &:hover{
      transition: 0.3s;
      background-color: #dfdfdf;
    }
  }
}

.tab-content{
  display: none;

  &.is-active{
    display: block;
    animation: fadeIn 1s ease;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}